export const territoriesTypes = {
  GET_REGIONS: 'GET_REGIONS',
  GET_PROVINCES: 'GET_PROVINCES',
  GET_MUNICIPALITIES: 'GET_MUNICIPALITIES',

  GET_PROVINCES_BY_REGION: 'GET_PROVINCES_BY_REGION',

  GET_MUNICIPALITIES_BY_PROVINCES_BY_REGIONS:
    'GET_MUNICIPALITIES_BY_PROVINCES_BY_REGIONS',

  CLEAN_SELECT_PROVINCES: 'CLEAN_SELECT_PROVINCES',

  CLEAN_SELECT_MUNICIPALITIES: 'CLEAN_SELECT_MUNICIPALITIES',
}

export const zammadTypes = {
  GET_TICKETS: 'GET_TICKETS',
  GET_TICKETS_DATE: 'GET_TICKETS_DATE',
  GET_TICKETS_TWO_DATE: 'GET_TICKETS_TWO_DATE',
  GET_TICKETS_STATE: 'GET_TICKETS_STATE',
  GET_TICKETS_GROUP: 'GET_TICKETS_GROUP',

  GET_USERS: 'GET_USERS',
  GET_USER_ME: 'GET_USER_ME',

  // GET_AUTH_USER: 'GET_AUTH_USER',

  GET_ROLS: 'GET_ROLS',

  GET_ORGANIZATIONS: 'GET_ORGANIZATIONS',
}
